import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  DatePicker,
  Form,
  Input,
  Tooltip,
  ConfigProvider,
  Select,
  Checkbox,
  notification,
  message,
  Modal,
} from "antd";
import axios from "axios";
import { Space, Table, Tag } from "antd";
//import { FileDrop } from 'react-file-drop';
import Papa from "papaparse";
import {
  UploadOutlined,
  HomeTwoTone,
  ThunderboltTwoTone,
  HddTwoTone,
  InfoCircleTwoTone,
  DeleteOutlined,
  StarOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
// import { ReactComponent as BatteryIcon } from "./battery.svg";
//const [packagingFields, setPackagingFields] = useState(['']);
import { ConfigService } from "../services/configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSolarPanel,
  faWind,
  faBatteryFull,
  faFlask,
  faGenerator,
} from "@fortawesome/free-solid-svg-icons";
import Chart from "chart.js";
import "chartjs-plugin-zoom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withTranslation } from "./WithTranslation";
// Now you can use moment in your code
import { axiosApiInstance } from "../services/axiosAPI";
import "./App.css";

const { Option } = Select;

class ConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      generatorList: [
        {
          type: "pvSolar",
          name: "Name1",
          capacity: "Capacity",
          panelPowerW: "Orientation",
          tiltAngle: "tiltAngle",
          nameW: "nameW",
          capacityW: "capacityW",
          std: "std",
          hubHeightm: "heigh",
        },
      ],
      types: ["pvSolar", "windturbine", "Diesel*"],
      buildingList: [
        {
          type: "hospital",
          nameH: "Name1",
          yearlykWh: "yearlykWh",
          nameC: "NameC",
          nameHP: "NameHP",
          weekdaykWh: "weekday",
          weekendkWh: "weekend",
          monthlykWh: "monthly",
          yearlyloads: "Yearlyloads",
          COP: "COP",
          yearlyHP: "YearlyHP",
        },
      ],
      buildingtypes: [
        "custom",
        "hospital",
        "hotel",
        "office",
        "residential",
        "school",
        "commercial",
        "industrial",
        "heatpump",
        "EVs",
      ],
      storageList: [
        {
          type: "Battery",
          nameB: "NameB",
          capacityB: "Capacity",
          nameR: "NameR",
          fuelCellPowerkW: "fuel",
          electrolyserPowerkW: "fuelkW",
        },
      ],
      storageTypes: ["Battery", "FuelCell", "Thermal*"],
      selectedStd: "IEC_1",
      yearlyloads: [],
      selectedFile: [],
      configurations: [],
      // configurationID,
      files: {},
      loads: {},
      isPopupVisible: false,

      isChecked: true,
      isCheckedS: false,
      isCheckedB: true,
      isCheckedC: false,
      isCheckedT: false,
      isCheckedA: true,
      loading: true,
      visibleModal: false,
      deleteconfigID: null,
      errorMessage: "",
      verrourHPC: false,
      configurationID: null,
      COF: 1,
      COP: 1,

      name: "",
      verrouE: false,
      location: {
        country: "", // For the "Country" input
        town: "", // For the "Town" input
      },
      date: "2022-01-01 00:00:00", // Default date value
    };
    this.popupRef = createRef();

    this.getData = this.getData.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await axiosApiInstance.get(
        `api/v1/?username=${localStorage.getItem("user")}`
      );
      const configurations = response.data;

      // Set configurations in state
      this.setState({ configurations, loading: false });
    } catch (error) {
      console.error("Error fetching configurations:", error);
      this.setState({ loading: false });
      message.error("Error fetching configurations. Please try again later.");
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // Close the WebSocket connection when the component is unmounted
    // this.socket.close();
    /*  if (this.chart) {
    this.chart.destroy();
  } */
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.setState = (state, callback) => {
      return;
    };
    // this.socket.disconnect();
  }

  async componentDidUpdate() {
    if (this.state.loading) {
      await axiosApiInstance
        .get(`api/v1/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ configurations: res.data, loading: false });
          console.log("conf", this.state.configurations);
        })
        .catch((error) => {
          console.log("ERROR::: ", error.res);
        });
      this.setState({ loading: false });
    }
  }

  handleIconClick = () => {
    this.setState({ isPopupVisible: true });
  };

  handleClickOutside = (event) => {
    if (
      this.popupRef.current &&
      !this.popupRef.current.contains(event.target)
    ) {
      this.setState({ isPopupVisible: false });
    }
  };

  getData(results) {
    this.setState({ data: results.data });
  }

  handleDelete(configurationID) {
    // Perform deletion logic using the configurationID
    this.setState({ deleteconfigID: configurationID, visibleModal: true });
  }

  handleEdit(param) {
    // Perform deletion logic using the configurationID
    //this.setState({ configurationID: configurationID });
    console.log(this.state.configurations, param);
    const selectedConfig = this.state.configurations.find(
      (config) => config.configurationID === param
    );
    console.log(
      "selelcc zv lmev",
      selectedConfig.name,
      selectedConfig.location,
      selectedConfig.generators,
      selectedConfig.storages.length
    );

    let loads = [];
    if (selectedConfig.buildings && selectedConfig.buildings.length > 0) {
      loads = selectedConfig.buildings.map((building) => {
        const COF = parseFloat(building.COF || 1); // Parse COF as a float
        const COP = parseFloat(building.COP || 1); // Parse COP as a float

        if (building.type === "custom") {
          // Divide each element of yearlyLoads by COF
          return building.yearlyloads.map((load) => load / COF);
        } else if (building.type === "heatpump") {
          // Multiply each element of yearlyHP by COP
          return building.yearlyHP.map((load) => load * COP);
        } else {
          // For unrecognized types, return an empty array
          return [];
        }
      });
    }
    this.setState({
      name: selectedConfig.name,
      location: selectedConfig.location,
      generatorList: selectedConfig.generators,
      buildingList: selectedConfig.buildings,
      storageList: selectedConfig.storages,
      date: selectedConfig.date,
      loads,
      verrouE: true,
      configurationID: param,
      // country: selectedConfig.country,
    });

    if (selectedConfig.buildings && selectedConfig.buildings.length > 0) {
      this.setState.loads = selectedConfig.buildings.map((building, i) => {
        if (building.type === "custom") {
          return building.yearlyloads / building.COF;
        } else if (building.type === "heatpump") {
          return building.yearlyHP * building.COP;
        } else {
          return 0; // Default value if the type is not recognized
        }
      });
    }

    if (selectedConfig.storages.length > 0) {
      this.setState({ isCheckedS: true });
    }
  }

  handleCancelDelete = () => {
    this.setState({ deleteconfigID: null, visibleModal: false });
  };

  /* console.log('Deleting configuration with ID:', configurationID);
  // Here you can implement the logic to delete the configuration


  await axios.delete(`https://www.sptapp.eu/api/v1/${configurationID}/deleteC/`)
  .then ()
    .catch(error => {
      console.log("ERROR::: ",error.res);});


  await axios.get(`https://www.sptapp.eu/api/v1/?username=${localStorage.getItem('user')}`)
   .then (res =>{
 this.setState({ configurations: res.data, loading:false}); console.log("conf", this.state.configurations);  
 })
 .catch(error => {
   console.log("ERROR::: ",error.res);});
};
 */

  handleConfirmDelete = async () => {
    const { deleteconfigID } = this.state;
    try {
      await axiosApiInstance.delete(`api/v1/${deleteconfigID}/deleteC/`);
      await axiosApiInstance
        .get(`api/v1/?username=${localStorage.getItem("user")}`)
        .then((res) => {
          this.setState({ configurations: res.data, loading: false });
          console.log("conf", this.state.configurations);
        });
      this.setState({ deleteconfigID: null, visibleModal: false });
    } catch (error) {
      console.error("Error deleting configuration:", error);
    }
  };

  handleSubmit_new = async (
    e,
    requestType,
    configurationID,
    datacsv,
    datacsvend,
    datacsvM
  ) => {
    e.preventDefault(); // Prevent the default page reload behavior
    console.log("Form submitted with values:", {
      requestType,
      configurationID,
      datacsv,
      datacsvend,
      datacsvM,
    });

    // Your custom logic here
    return;
  };

  handleSubmit = async (
    e,
    requestType,
    configurationID,
    datacsv,
    datacsvend,
    datacsvM
  ) => {
    // const country = useRef()
    // const town = useRef()
    //console.log("datacsv",datacsv)
    e.preventDefault();

    console.log(
      "valuesall",
      this.state.loads,
      e.target.elements,
      this.state.configurations,
      this.state.storageList
    );

    e.preventDefault();
    console.log("Form Data:", e.target.elements);

    // Log all form data by iterating over e.target.elements
    console.log("Form Data **********:");
    Array.from(e.target.elements).forEach((element) => {
      // Avoid logging non-input elements (like form buttons)
      if (element.name) {
        console.log(`${element.name}: ${element.value}`);
      }
    });

    const date = e.target.elements.date.value;
    const country = e.target.elements.country.value;
    const town = e.target.elements.town.value;
    const name = e.target.elements.name.value;

    //console.log("hello alert", e.target.elements.date.value)
    if (!e.target.elements.date.value) {
      //console.log("hello alert")
      // Show an alert using Ant Design's message component
      message.error("Please select a start date");
      return;
    }

    let batteryCount = 0;
    if (this.state.isCheckedS === true) {
      for (let i = 0; i < this.state.storageList.length; i++) {
        if (this.state.storageList[i].type === "Battery") {
          this.state.storageList[i].nameB =
            e.target.elements[`nameB_${i}`].value;
          this.state.storageList[i].capacityB = parseFloat(
            e.target.elements[`capacityB_${i}`].value
          );
        } else if (this.state.storageList[i].type === "FuelCell") {
          this.state.storageList[i].nameR =
            e.target.elements[`nameR_${i}`].value;
          this.state.storageList[i].fuelCellPowerkW = parseFloat(
            e.target.elements[`fuelCellPowerkW_${i}`].value
          );
          this.state.storageList[i].electrolyserPowerkW = parseFloat(
            e.target.elements[`electrolyserPowerkW_${i}`].value
          );
        }
      }
    } else {
      this.state.storageList = [];
    }

    // Generators list filling

    if (this.state.isChecked === true) {
      for (let i = 0; i < this.state.generatorList.length; i++) {
        if (this.state.generatorList[i].type === "pvSolar") {
          this.state.generatorList[i].name =
            e.target.elements[`name_${i}`].value;
          this.state.generatorList[i].capacity = parseFloat(
            e.target.elements[`capacity_${i}`].value
          );
          this.state.generatorList[i].panelPowerW = parseFloat(
            e.target.elements[`panelPowerW_${i}`].value
          );
          this.state.generatorList[i].tiltAngle = parseFloat(
            e.target.elements[`tiltAngle_${i}`].value
          );

          // console.log("nothinggg", e.target.elements.std_1.value)
        } else if (this.state.generatorList[i].type === "windturbine") {
          this.state.generatorList[i].nameW =
            e.target.elements[`nameW_${i}`].value;
          this.state.generatorList[i].capacityW = parseFloat(
            e.target.elements[`capacityW_${i}`].value
          );
          this.state.generatorList[i].std = this.state.selectedStd; //e.target.elements[`std_${i}`].value;  be carefull .............
          this.state.generatorList[i].hubHeightm = parseFloat(
            e.target.elements[`hubHeightm_${i}`].value
          );
        }
      }
    } else {
      this.state.generatorList = [];
    }

    if (this.state.isCheckedB === true) {
      for (let i = 0; i < this.state.buildingList.length; i++) {
        if (
          this.state.buildingList[i].type != "custom" &&
          this.state.buildingList[i].type != "heatpump"
        ) {
          // console.log("loadss ", this.state.loads[i], i);

          this.state.buildingList[i].nameH =
            e.target.elements[`nameH_${i}`].value;
          this.state.buildingList[i].yearlykWh = parseFloat(
            e.target.elements[`yearlykWh_${i}`].value
          );
        } else if (this.state.buildingList[i].type === "custom") {
          this.state.buildingList[i].nameC =
            e.target.elements[`nameC_${i}`].value;
          console.log("loadss ", this.state.loads[i], i);
          const loadsArray = this.state.loads[i].map((load) =>
            parseFloat(load)
          );
          const COF = parseFloat(e.target.elements[`COF_${i}`].value);
          console.log("New COF:", COF);

          //this.state.buildingList[i].COP =e.target.elements[`COP_${i}`].value;
          if (!isNaN(COF)) {
            const newLoadArrayC = loadsArray.map((load) =>
              parseFloat((load * Number(COF)).toFixed(2))
            );
            console.log("New load:", newLoadArrayC);
            this.state.buildingList[i].yearlyloads = newLoadArrayC;
          } else {
            this.state.buildingList[i].yearlyloads = this.state.loads[i];
            console.log("==1", this.state.loads[i]);
          }
        } else if (this.state.buildingList[i].type === "heatpump") {
          const loadsArray = this.state.loads[i].map((load) =>
            parseFloat(load)
          );
          const COP = parseFloat(e.target.elements[`COP_${i}`].value);
          //const COP = Number(COPS);
          //console.log("cop", COPS, COP, e.target.elements[`COP_${i}`]);
          // Check if COP is not 0 to avoid division by zero
          if (COP !== 0) {
            const newLoadArray = loadsArray.map((load) =>
              parseFloat((load / Number(COP)).toFixed(2))
            );
            console.log("New load:", newLoadArray);
            this.state.buildingList[i].nameHP =
              e.target.elements[`nameHP_${i}`].value;
            this.state.buildingList[i].COP = COP;
            this.state.buildingList[i].yearlyHP = newLoadArray;
            console.log("New load:", newLoadArray);
          } else {
            console.error("COP cannot be zero.");
          }
        }
      }
    } else {
      this.state.buildingList = [];
    }

    const csvoutput = datacsv;
    //const csvoutput =  this.props.results.data; //Papa.parse(e.target.files[0])///e.target.elements.csvFile.result);

    //const title= document.getElementById('country').value;
    //
    //const content= document.getElementById('town').value;
    //
    if (!this.state.verrouE) {
      console.log("verourur e", this.state.verrouE);
      if (
        this.state.configurations.some(
          (config) => config.name === e.target.elements.name.value
        )
      ) {
        message.error("Name already exists! Please choose a different name.");
        return;
      }
      switch (requestType) {
        // ConfigService.create( {   axios.post('https://www.sptapp.eu/api/v1/createC/', {
        //axios.post('https://spt-vpp4islands.herokuapp.com/api/v1/createC/', {
        case "post":
          return ConfigService.create({
            date: date,
            username: localStorage.getItem("user"),
            name: name,
            location: { country: country, town: town },
            // pvSolar:{name: name, capacity: parseFloat(capacity), panelPowerW: parseFloat(panelPowerW), tiltAngle:parseFloat(tiltAngle)},
            storages: this.state.storageList,

            generators: this.state.generatorList,

            //regenerativeHydrogens:[{name: nameR, fuelCellPowerkW: parseFloat(fuelCellPowerkW), electrolyserPowerkW: parseFloat(electrolyserPowerkW)}],
            buildings: this.state.buildingList,
            weekday: csvoutput,
            weekend: datacsvend,
            monthly: datacsvM,
          })

            .then((res) => {
              console.log(res);
              this.setState({ loading: true });
              message.success("Successful configuration");
              this.setState({
                generatorList: [],
                storageList: [],
                buildingList: [],
                name: "",

                location: {
                  country: "", // For the "Country" input
                  town: "", // For the "Town" input
                },
                date: "2022-01-01 00:00:00",
              });
            })
            .catch((error) => console.error(error));

        case "put":
          // (username = localStorage.getItem("user")),
          console.log("dattatataa", localStorage.getItem("user"));

        case "delete":
          return axiosApiInstance
            .delete(`api/v1/${configurationID}/deleteC/`)
            .then((res) => console.log(res))
            .catch((error) => console.error(error));
      }
    } else {
      console.log("verourur e", this.state.verrouE);

      // (username = localStorage.getItem("user")),
      console.log("dattatataa", localStorage.getItem("user"));
      return axiosApiInstance
        .put(`api/v1/${this.state.configurationID}/updateC/`, {
          date: date,
          username: localStorage.getItem("user"),
          name: name,
          location: { country: country, town: town },
          // pvSolar:{name: name, capacity: parseFloat(capacity), panelPowerW: parseFloat(panelPowerW), tiltAngle:parseFloat(tiltAngle)},
          storages: this.state.storageList,

          generators: this.state.generatorList,

          //regenerativeHydrogens:[{name: nameR, fuelCellPowerkW: parseFloat(fuelCellPowerkW), electrolyserPowerkW: parseFloat(electrolyserPowerkW)}],
          buildings: this.state.buildingList,
          weekday: csvoutput,
          weekend: datacsvend,
          monthly: datacsvM,
        })
        .then((res) => {
          this.setState({ loading: true });
          message.success("Configuration updated");
          this.setState({
            generatorList: [],
            storageList: [],
            buildingList: [],
            name: "",

            location: {
              country: "", // For the "Country" input
              town: "", // For the "Town" input
            },
            date: "2022-01-01 00:00:00",
          });
          this.setState({ verrouE: false });

          console.log(res);
        })
        .catch((error) => console.error(error));
    }
  };

  handleCancel = () => {
    this.setState({ verrouE: false });
    this.setState({
      generatorList: [],
      storageList: [],
      buildingList: [],
      name: "",

      location: {
        country: "", // For the "Country" input
        town: "", // For the "Town" input
      },
      date: "2022-01-01 00:00:00",
    });
    // this.setState({ title: "Enter a unique name" });
    /*  this.setState({
      location: { country: "Enter a country", town: "Enter a town" },
    }); */
  };
  /*  handleUpdate = () => {
    this.setState({ verrouE: false });
    console.log("dattatataa", localStorage.getItem("user"));
    return axiosApiInstance
      .put(`api/v1/${this.state.configurationID}/updateC/`, {
        date: date,
        username: localStorage.getItem("user"),
        name: name,
        location: { country: country, town: town },
        // pvSolar:{name: name, capacity: parseFloat(capacity), panelPowerW: parseFloat(panelPowerW), tiltAngle:parseFloat(tiltAngle)},
        storages: this.state.storageList,


        generators: this.state.generatorList,


        //regenerativeHydrogens:[{name: nameR, fuelCellPowerkW: parseFloat(fuelCellPowerkW), electrolyserPowerkW: parseFloat(electrolyserPowerkW)}],
        buildings: this.state.buildingList,
        weekday: csvoutput,
        weekend: datacsvend,
        monthly: datacsvM,
      })
      .then((res) => console.log(res))
      .catch((error) => console.error(error));
  }; */

  handleStorageTypeChange_old = (value, index) => {
    this.setState((prevState) => {
      const updatedStorageList = [...prevState.storageList];
      const newStorage = {
        type: value,
        nameB: "Name",
        capacityB: "Capacity",
        nameR: "NameR",
        fuelCellPowerkW: "fuel",
        electrolyserPowerkW: "fuelkW",
      };

      console.log("nameR", newStorage.nameR);
      updatedStorageList[index] = newStorage;
      console.log("list", updatedStorageList[0]);

      return { storageList: updatedStorageList };
    });
  };

  handleStorageTypeChange = (event, index, key) => {
    console.log("valuueesss", event);

    const { value } = event.target;
    console.log("valuueesstorage", value, event);
    this.setState((prevState) => {
      const updatedStorageList = [...prevState.storageList];

      // Keep the existing storage properties while updating only the type
      updatedStorageList[index] = {
        ...updatedStorageList[index], // Preserve existing properties
        [key]: value, // Update only the type property
      };
      this.setState({ isCheckedS: true });

      return { storageList: updatedStorageList };
      //this.setState({ isCheckedS: true });
    });
  };

  clearInputs = (index) => {
    // Loop through inputs and clear their values
    const inputsToClear = [
      `nameH_${index}`,
      `yearlykWh_${index}`,
      `nameHP_${index}`,
      `COP_${index}`,
      `nameC_${index}`,
    ];

    console.log("i clean");

    inputsToClear.forEach((inputName) => {
      const input = document.querySelector(`input[name="${inputName}"]`);
      console.log("inputs", input);
      if (input) {
        input.value = ""; // Clear input value
      }
    });
  };

  renderStorageConfig = (storage, index) => {
    const { type } = storage;
    console.log("storage", storage.nameR);
    if (type === "Battery") {
      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameB_${index}`}
                // defaultValue={storage.nameB}
                placeholder="Name"
                value={storage.nameB || ""}
                onChange={(e) =>
                  this.handleStorageTypeChange(e, index, "nameB")
                }
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Capacity
              </label>
              <Input
                type="number"
                placeholder="Capacity"
                name={`capacityB_${index}`}
                value={storage.capacityB || ""}
                onChange={(e) =>
                  this.handleStorageTypeChange(e, index, "capacityB")
                }
                style={{ flex: 1 }}
              />
            </div>
          </Input.Group>
        </>
      );
    } else if (type === "FuelCell") {
      const defaultValues = {
        nameR: "defaultName",
        fuelCellPowerkW: "defaultFuelCellPowerkW",
        electrolyserPowerkW: "defaultElectrolyserPowerkW",
      };

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameB_${index}`}
              // defaultValue=""
              placeholder="Name"
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`capacityB_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />

            {/* Display the values for FuelCell */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameR_${index}`}
                // defaultValue={defaultValues.nameR}
                placeholder="Name"
                value={storage.nameR || ""}
                onChange={(e) =>
                  this.handleStorageTypeChange(e, index, "nameR")
                }
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                FuelCellPowerkW
              </label>
              <Input
                name={`fuelCellPowerkW_${index}`}
                // defaultValue={defaultValues.fuelCellPowerkW}
                placeholder="fuelCellPowerkW"
                type="number"
                value={storage.fuelCellPowerkW || ""}
                onChange={(e) =>
                  this.handleStorageTypeChange(e, index, "fuelCellPowerkW")
                }
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Electrolyser PowerkW
              </label>
              <Input
                name={`electrolyserPowerkW_${index}`}
                // defaultValue={defaultValues.electrolyserPowerkW}
                placeholder="electrolyserPowerkW"
                type="number"
                value={storage.electrolyserPowerkW || ""}
                onChange={(e) =>
                  this.handleStorageTypeChange(e, index, "electrolyserPowerkW")
                }
                style={{ flex: 1 }}
              />
            </div>
          </Input.Group>
        </>
        // TODO to add Capcity to FuelCELL
      );
    } else {
      // Handle other types if needed
      return null;
    }
  };

  // File load

  handleOnChange = (e, i, callback) => {
    e.preventDefault();

    Papa.parse(e.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = [];
        const parsedValues = results.data.map((values) => Number(values[0]));
        console.log(parsedValues);
        callback(parsedValues);
      },
    });
  };

  // Building creation and adding

  /* handleAddBuilding = () => {
    this.setState((prevStateB) => ({
      buildingList: [...prevStateB.buildingList, { nameH: "", yearlykWh: "" }],
    }));
  }; */

  /*  handleBuildingTypeChange = (value, index) => {
    this.setState((prevStateB) => {
      const updatedBuildingList = [...prevStateB.buildingList];
      const newBuilding = {
        type: value,
        nameH: "Name1",
        yearlykWh: "yearlykWh",
        NameC: "NameC",
        weekdaykWh: "weekday",
        weekendkWh: "weekend",
        monthlykWh: "monthly",
        yearlyloads: "yearlyloads",
      };


      console.log("nameH", newBuilding.nameH);
      updatedBuildingList[index] = newBuilding;
      console.log("list", updatedBuildingList[0]);


      return { buildingList: updatedBuildingList };
    });
  }; */
  handleBuildingTypeChange = (value, index, key) => {
    this.setState((prevState) => {
      const updatedBuildingList = [...prevState.buildingList]; // Copy the existing building list

      // Update the specific key for the building at the given index
      updatedBuildingList[index] = {
        ...updatedBuildingList[index], // Keep other properties unchanged
        [key]: value, // Update only the specific key
      };

      return { buildingList: updatedBuildingList }; // Update the state with the modified list
    });
  };

  renderBuildingConfig = (building, index) => {
    const { type } = building;
    console.log("Building", building.nameH);
    const defaultValuesH = {
      nameH: "defaultNameH",
      yearlykWh: "yearlykWh",
      nameC: "defaultNameC",
      nameHP: "defaultNameHP",
      COP: 1,
      COF: 1,
    };

    if (type != "custom" && type != "heatpump") {
      this.clearInputs(index);

      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameH_${index}`}
                placeholder={defaultValuesH.nameH}
                value={building.nameH || ""}
                onChange={(e) =>
                  this.handleBuildingTypeChange(e.target.value, index, "nameH")
                }
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                YearlykWh
              </label>
              <Input
                name={`yearlykWh_${index}`}
                type="number"
                placeholder={defaultValuesH.yearlykWh}
                value={building.yearlykWh}
                onChange={(e) =>
                  this.handleBuildingTypeChange(
                    e.target.value,
                    index,
                    "yearlykWh"
                  )
                }
                style={{ flex: 1 }}
              />
            </div>
          </Input.Group>
        </>
      );
    } else if (type === "custom") {
      console.log("typpeeec", type);
      this.clearInputs(index);

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameH_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlykWh_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlyHP_${index}`}
              style={{ flex: 1, display: "none" }}
              type="file"
            />
            <Input
              name={`nameHP_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input name={`COP_${index}`} style={{ flex: 1, display: "none" }} />

            {/* Display the values for FuelCell */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameC_${index}`}
                placeholder="name"
                value={building.nameC || ""}
                onChange={(e) =>
                  this.handleBuildingTypeChange(e.target.value, index, "nameC")
                }
                style={{ flex: 1 }}
              />
            </div>
            {/*         <Input name={`COP_${index}`} defaultValue={defaultValuesH.COP} style={{ flex: 1 }} />
             */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Yealy Loads
              </label>
              <Input
                name={`yearlyloads_${index}`}
                style={{ flex: 1 }}
                type="file"
                //id={""}
                accept=".csv"
                id={`csvfile_${index}`}
                onChange={(e) =>
                  this.handleOnChange(e, index, (parsedValues) => {
                    // Do something with parsedValues, for example, set it in the state
                    this.state.loads[index] = parsedValues;
                    console.log("Parsed Values:", parsedValues);
                    // this.setState({ yourStateVariable: parsedValues });
                  })
                }

                //this.handleOnChange(e, index)

                //style={{ display: "block", margin: "10px auto" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                COF
              </label>
              <Input
                name={`COF_${index}`}
                placeholder={defaultValuesH.COF}
                type="number"
                value={
                  building.COF !== undefined ? building.COF : defaultValuesH.COF
                }
                onChange={(e) =>
                  this.handleBuildingTypeChange(e.target.value, index, "COF")
                }
                style={{ flex: 1 }}
              />
            </div>
          </Input.Group>
        </>
      );
    } else if (type === "heatpump") {
      console.log("typpeeec", defaultValuesH.COP);
      this.clearInputs(index);

      return (
        <>
          {/* Clear the values for Battery when changing to FuelCell */}
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`nameH_${index}`}
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`yearlykWh_${index}`}
              style={{ flex: 1, display: "none" }}
            />

            <Input
              name={`yearlyloads_${index}`}
              style={{ flex: 1, display: "none" }}
              type="file"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameHP_${index}`}
                placeholder="name"
                value={building.nameHP || ""}
                onChange={(e) =>
                  this.handleBuildingTypeChange(e.target.value, index, "nameHP")
                }
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Yearly HP
              </label>
              <Input
                name={`yearlyHP_${index}`}
                style={{ flex: 1 }}
                type="file"
                //id={""}
                accept=".csv"
                id={`csvfile_${index}`}
                onChange={(e) =>
                  this.handleOnChange(e, index, (parsedValues) => {
                    // Do something with parsedValues, for example, set it in the state
                    this.state.loads[index] = parsedValues;
                    console.log("Parsed Values:", parsedValues);
                    // this.setState({ yourStateVariable: parsedValues });
                  })
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                COP
              </label>
              <Input
                name={`COP_${index}`}
                placeholder={defaultValuesH.COP}
                value={
                  building.COP !== undefined ? building.COP : defaultValuesH.COP
                }
                onChange={(e) =>
                  this.handleBuildingTypeChange(e.target.value, index, "COP")
                }
                style={{ flex: 1 }}
                type="number"
              />
            </div>
          </Input.Group>
        </>
      );
    } else {
      // Handle other types if needed
      return null;
    }
  };

  // generation creation and adding

  handleStdChange = (value, index) => {
    console.log("value", value);
    this.setState({ selectedStd: value });
    this.state.generatorList[index].std = value;
    console.log("stddd", this.state.generatorList[index].std);
    console.log("index", index);
  };

  //     ***********    Check boxes ***********

  handleCheckboxChange = (e) => {
    this.setState({ isChecked: e.target.checked });
  };

  handleCheckboxSChange = (e) => {
    this.setState({ isCheckedS: e.target.checked });
  };

  handleCheckboxBChange = (e) => {
    this.setState({ isCheckedB: e.target.checked });
  };

  handleCheckboxCChange = (e) => {
    this.setState({ isCheckedC: e.target.checked });
  };

  handleCheckboxTChange = (e) => {
    this.setState({ isCheckedT: e.target.checked });
  };

  handleCheckboxAChange = (e) => {
    this.setState({ isCheckedA: e.target.checked });
  };

  handleAddStorage = () => {
    const newStorage = {
      type: "",
      nameB: "NameB",
      capacityB: "capacity",
      nameR: "NamexR",
      fuelCellPowerkW: "fuel",
      electrolyserPowerkW: "fuelkW",
    };

    // Check if all user-entered values in the new storage are not empty

    this.setState((prevState) => ({
      storageList: [...prevState.storageList, newStorage],
    }));
  };

  handleAddGenerator = () => {
    console.log("value");
    this.setState((prevStateG) => ({
      generatorList: [
        ...prevStateG.generatorList,
        { name: "", capacity: "", panelPowerW: "", tiltAngle: "" },
      ],
    }));
    console.log("value", this.state.generatorList);
  };

  handleAddBuilding = () => {
    this.setState((prevStateB) => ({
      buildingList: [...prevStateB.buildingList, { nameH: "", yearlykWh: "" }],
    }));
  };

  DeleteExtra = (value, index) => {
    this.setState((prevStateG) => {
      const updatedGeneratorList = [...prevStateG.generatorList];
      updatedGeneratorList.splice(index, 1); // Remove the generator at the given index
      return { generatorList: updatedGeneratorList };
    });
  };

  DeleteExtraB = (value, index) => {
    this.setState((prevStateB) => {
      const updatedBList = [...prevStateB.buildingList];
      updatedBList.splice(index, 1); // Remove the generator at the given index
      return { buildingList: updatedBList };
    });
  };
  DeleteStorage = (value, index) => {
    this.setState((prevState) => {
      const updatedSList = [...prevState.storageList];
      updatedSList.splice(index, 1); // Remove the generator at the given index
      return { storageList: updatedSList };
    });
  };

  /*   handleTypeChange = (value, index) => {
    console.log("valuegen", value);


    this.setState((prevStateG) => {
      const updatedGeneratorList = [...prevStateG.generatorList];
      const newGenerator = {
        type: value,
        name: "Name1",
        capacity: "Capacity",
        panelPowerW: "Orientation",
        tiltAngle: "tiltAngle",
        nameW: "nameW",
        capacityW: "capacityW",
        std: "IEC_1",
        hubHeightm: "heigh",
      };


     
      console.log("std", newGenerator.std);
      updatedGeneratorList[index] = newGenerator;
      console.log("list", updatedGeneratorList[0]);


      return { generatorList: updatedGeneratorList };
    });
  }; */

  getDefaultGenerator = (type) => ({
    type: type,
    name: "Name1",
    capacity: "Capacity",
    panelPowerW: "Orientation",
    tiltAngle: "tiltAngle",
    nameW: "nameW",
    capacityW: "capacityW",
    std: "IEC_1",
    hubHeightm: "height",
  });

  handleTypeChange = (event, index, key) => {
    const { value } = event.target; // Extract the value from the input event
    console.log("valuueee", value, index, key);
    this.setState((prevState) => {
      const updatedGeneratorList = [...prevState.generatorList]; // Copy the current generator list
      updatedGeneratorList[index] = {
        ...updatedGeneratorList[index], // Copy the existing generator object at the index
        [key]: value, // Update the specific key with the new value
      };

      return { generatorList: updatedGeneratorList }; // Update the state with the modified list
    });
  };

  renderInputGroup = (generator, index) => {
    const { type } = generator;
    // const { t } = this.props;
    const defaultValuesH = {
      orientationG: "orientation",
      inclinationG: "inclination",
      capacityG: "capacity",
    };
    if (type === "pvSolar") {
      return (
        <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <label style={{ marginBottom: "-4px", fontSize: 13 }}>Name</label>
            <Input
              name={`name_${index}`}
              //defaultValue={generator.name}
              placeholder="Name"
              value={generator.name || ""}
              onChange={(e) => this.handleTypeChange(e, index, "name")}
              style={{ flex: 1 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <label
              className="country-label"
              style={{ fontSize: 13, marginBottom: "-4px" }}
            >
              Capacity
            </label>
            <Input
              type="number"
              name={`capacity_${index}`}
              placeholder={defaultValuesH.capacityG}
              value={generator.capacity}
              onChange={(e) => this.handleTypeChange(e, index, "capacity")}
              style={{ flex: 1 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <label
              className="country-label"
              style={{ fontSize: 13, marginBottom: "-4px" }}
            >
              Orientation
            </label>
            <Input
              type="number"
              name={`panelPowerW_${index}`}
              // defaultValue={generator.panelPowerW}
              placeholder={defaultValuesH.orientationG}
              value={generator.panelPowerW}
              onChange={(e) => this.handleTypeChange(e, index, "panelPowerW")}
              style={{ flex: 1 }}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <label
              className="country-label"
              style={{ fontSize: 13, marginBottom: "-4px" }}
            >
              Inclination
            </label>
            <Input
              type="number"
              name={`tiltAngle_${index}`}
              //defaultValue={generator.tiltAngle}
              placeholder={defaultValuesH.inclinationG}
              value={generator.tiltAngle}
              onChange={(e) => this.handleTypeChange(e, index, "tiltAngle")}
              style={{ flex: 1 }}
            />
          </div>
        </Input.Group>
      );
    } else if (type === "windturbine") {
      const defaultValues = {
        nameW: "defaultName",
        capacityW: "CapacityW",
        std: "IEC_1",
        hubHeightm: "defualtHeight",
      };
      const stdOptions = ["IEC_1", "IEC_2", "IEC_3", "offshore"];
      return (
        <>
          <Input.Group style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Input
              name={`name_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`capacity_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`panelPowerW_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />
            <Input
              name={`tiltAngle_${index}`}
              defaultValue=""
              style={{ flex: 1, display: "none" }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Name
              </label>
              <Input
                name={`nameW_${index}`}
                // defaultValue={defaultValues.nameW}
                placeholder="Name"
                value={generator.nameW || ""}
                onChange={(e) => this.handleTypeChange(e, index, "nameW")}
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Capacity
              </label>
              <Input
                name={`capacityW_${index}`}
                // defaultValue={defaultValues.capacityW}
                placeholder="capacity"
                value={generator.capacityW || ""}
                onChange={(e) => this.handleTypeChange(e, index, "capacityW")}
                style={{ flex: 1 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                Std
              </label>
              <Select
                name={`std_${index}`}
                defaultValue={defaultValues.std}
                value={generator.std}
                onChange={(value) => this.handleStdChange(value, index)}
                style={{ flex: 1 }}
              >
                {stdOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <label
                className="country-label"
                style={{ fontSize: 13, marginBottom: "-4px" }}
              >
                HubHeightm
              </label>
              <Input
                name={`hubHeightm_${index}`}
                // defaultValue={defaultValues.hubHeightm}
                placeholder="hubHeightm"
                value={generator.hubHeightm || ""}
                onChange={(e) => this.handleTypeChange(e, index, "hubHeightm")}
                style={{ flex: 1 }}
              />
            </div>
          </Input.Group>{" "}
        </>
      );
    } else {
      // Render other types here if needed
      return null;
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (["country", "town"].includes(name)) {
      // Update nested location fields
      this.setState((prevState) => ({
        location: {
          ...prevState.location,
          [name]: value,
        },
      }));
    } else {
      // Update non-nested fields
      this.setState({ [name]: value });
    }
  };
  handleDateChange = (date, dateString) => {
    this.setState({ date: dateString }); // Store the formatted date string
  };
  /*   onFinish = (values) => {
    // Check if the date field is empty
    if (!values.date) {
      // Show an alert using Ant Design's message component
      message.error("Please select a start date");
      return;
    }
   }; */

  render() {
    const {
      buildingList,
      buildingtypes,
      generatorList,
      types,
      isPopupVisible,

      storageList,
      storageTypes,
      errorMessage,
    } = this.state;
    const { name, location, date, verrouE } = this.state; // Destructure state
    const { country, town } = location;
    console.log("titltlt", name);
    // const {t, i18n} = useTranslation()

    const { t } = this.props;

    const paginationConfig = {
      pageSize: 5, // Set the number of rows per page
      // You can add more pagination options here if needed
    };

    const loc = [this.state.configurations.location];

    const columns = [
      {
        title: t("Configuration_name"),
        // title: "Configuration_name",
        dataIndex: "name",
        key: "name",
        render: (text) => <a>{text}</a>,
      },
      {
        title: t("ConfigID"),
        // title: "ConfigID",
        dataIndex: "configurationID",
        key: "configurationID",
      },
      {
        title: t("Location"),
        // title: "Location",
        dataIndex: "location",
        key: "location",
        render: (_, { location }) => (
          <Tag key={location.town} style={{ marginRight: "5px" }}>
            {location.town.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: t("Creation_date"),
        // title: "Creation_date",
        dataIndex: "date_creation",
        key: "date_creation",
      },
      {
        title: t("Assets"),
        // title: "Assets",
        key: "generators",
        dataIndex: "generators",
        render: (_, { generators, storages }) => (
          <>
            {generators.map((generator) => {
              let color =
                generator.type === "pvSolar"
                  ? "blue"
                  : generator.type === "windturbine"
                  ? "orange"
                  : "default";
              let icon =
                generator.type === "pvSolar"
                  ? faSolarPanel
                  : generator.type === "windturbine"
                  ? faWind
                  : null;

              // Adjust color based on generator type or any other condition
              return (
                <Tag color={color} key={generator.type}>
                  {icon && (
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ marginRight: "5px" }}
                    />
                  )}

                  {generator.type.toUpperCase()}
                </Tag>
              );
            })}
            {storages.map((storage) => {
              let color =
                storage.type === "Battery"
                  ? "green"
                  : storage.type === "FuelCell"
                  ? "yellow"
                  : "default";
              let icon =
                storage.type === "Battery"
                  ? faBatteryFull
                  : storage.type === "FuelCell"
                  ? faFlask
                  : null;
              return (
                <Tag color={color} key={storage.type}>
                  {icon && (
                    <FontAwesomeIcon
                      icon={icon}
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {storage.type.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Actions",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <a onClick={() => this.handleDelete(record.configurationID)}>
              Delete
            </a>
            <a
              onClick={() => {
                this.setState({ verrouE: true });

                this.handleEdit(record.configurationID);
              }}
            >
              Edit
            </a>
          </Space>
        ),
      },
    ];

    console.log("conf2", this.state.configurations);
    //console.log("datac", dataC)
    /* const dataC= this.state.configurations;
    console.log("datac", dataC) */
    const { configurations, loading } = this.state;

    console.log("datac", configurations);

    const mappedConfigurations = configurations;

    console.log("datacmap", mappedConfigurations[0]);

    const validateStartDate = (rule, value) => {
      if (!value) {
        notification.error({
          message: "Validation Error",
          description: "Please select a start date.",
        });
        return Promise.reject("Please select a start date.");
      }
      return Promise.resolve();
    };

    return (
      <form
        onSubmit={(e) =>
          this.handleSubmit(
            e,
            this.props.requestType,
            this.props.configurationID,
            this.props.datacsv,
            this.props.datacsvend,
            this.props.datacsvM
            //   this.props.std
          )
        }
      >
        <div
          style={{
            display: "flex",
            width: "170vh",
            marginBottom: 10,
            backgroundColor: "#f0f0f0",
            padding: 15,
            border: "solid 1px",
            borderColor: "#cccccc",
          }}
        >
          {" "}
          <div>
            {/*  <div style={{ display: 'flex', height: '80vh' }}>
    <div style={{ flex: 1, width: '40%',  padding: '20px' }}> */}
            <Form.Item>
              <label htmlFor="name" className="country-label">
                <span style={{ color: "red" }}>*</span>
                {"Name"}:{" "}
                <span style={{ fontSize: "11px", color: "#888" }}>
                  {" "}
                  (Enter unique name)
                </span>
              </label>

              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Input
                  style={{
                    maxWidth: "206px",
                    maxHeight: "200px",
                  }}
                  placeholder="Enter unique name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={this.handleInputChange}
                  required
                />
              </div>
            </Form.Item>
            {/* Date Picker */}
            <Form.Item>
              <label htmlFor="date" className="country-label">
                <span style={{ color: "red" }}>*</span>
                {t("Date")}:{" "}
                <span style={{ fontSize: "11px", color: "#888" }}>
                  {" "}
                  (Enter a date that does not exceed 01-01-2023)
                </span>
              </label>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <DatePicker
                  name="date"
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                  value={this.state.date ? moment(this.state.date) : null}
                  onChange={this.handleDateChange} // Update state on date change
                  disabledDate={(current) =>
                    current && current.isAfter(moment("2023-01-02"))
                  }
                  showNow={false}
                />
              </div>
            </Form.Item>
            <div style={{ display: "flex" }}>
              <Form.Item

              //rules={[{ required: true, message: "Please enter the country" },  ]}
              >
                <label htmlFor="country" className="country-label">
                  {t("Country")}:{/* {"Country"}: */}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Input
                  style={{ width: "100%", maxWidth: "206px" }}
                  placeholder="Enter country"
                  name="country"
                  type="text"
                  value={this.state.location.country || ""}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Item>
              <Form.Item
              /*   style={{ marginLeft: 5 }}
                label={<span> {t("Town")} </span>}
                name="town" */
              // rules={[{ required: true, message: "Please enter the town" }]}
              >
                <label htmlFor="town" className="country-label">
                  <span style={{ color: "red" }}>*</span>
                  {t("Town")}:{/* {("Town")}: */}
                </label>
                <Input
                  // value={state}
                  style={{ width: "100%", maxWidth: "206px" }}
                  placeholder="Enter town"
                  value={this.state.location.town}
                  type="text"
                  name="town"
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Item>
            </div>
            <Divider orientation="left">
              {t("DIY Configuration")}
              {/* {("Select Your Config")}{" "} */}
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginLeft: "10px", // Add space between text and icon
                }}
              >
                <InfoCircleTwoTone
                  onClick={this.handleIconClick}
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                />
                {isPopupVisible && (
                  <div
                    ref={this.popupRef}
                    style={{
                      position: "absolute",
                      top: "30px", // Adjust as needed
                      left: "0",
                      border: "1px solid #ccc",
                      backgroundColor: "white",
                      padding: "10px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000, // Ensures the popup is above other elements
                    }}
                  >
                    PV Orientation is for azimut: 0=south, 180=north, -90= West
                    and
                    <br></br>
                    90= east. tiltAngle is the inclination of panel.
                    <br></br>
                    The correction factor is normalized by using a<br></br>
                    optimal azimuth across the range of tilt angles (0, 90).
                    <br></br> A configuration is composed at least by one
                    <br></br> generator and one load. Max starting date is
                    2023-01-01.
                    <br></br>You can modify the parameters of the simulation
                    <br></br> via the "settings" form at the top menu.
                  </div>
                )}{" "}
              </div>
            </Divider>

            <br></br>
            {generatorList.map((generator, index) => (
              <Form.Item
                label={<strong>Generator {index + 1}</strong>}
                key={index}
                style={{ display: "flex", gap: "8px", width: "100%" }}
              >
                {/* <Select
                    value={generator.type}
                    style={{ width: 100 }}
                    onChange={(value) =>
                      this.handleTypeChange(value, index, "type")
                    }
                  >
                    {types.map((type, typeIndex) => (
                      <Option key={typeIndex} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select> */}
                <Select
                  value={generator.type}
                  style={{ width: 100, marginBottom: 10 }}
                  onChange={(value) =>
                    this.handleTypeChange({ target: { value } }, index, "type")
                  }
                >
                  {this.state.types.map((type, typeIndex) => (
                    <Option key={typeIndex} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
                <Tooltip title="Delete">
                  <Button onClick={() => this.DeleteExtra(null, index)}>
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
                {this.renderInputGroup(generator, index)}
              </Form.Item>
            ))}

            <Form.Item>
              <Button
                type="primary"
                style={{ width: 250 }}
                onClick={this.handleAddGenerator}
              >
                {t("Add_Generator")}{" "}
                <ThunderboltTwoTone twoToneColor="#FFFF00" />
              </Button>
            </Form.Item>

            {storageList.map((storage, index) => (
              <Form.Item
                label={<strong>Storage {index + 1} </strong>}
                key={index}
                style={{ display: "flex", gap: "8px", width: "100%" }}
              >
                <Select
                  value={storage.type}
                  style={{ width: 100, marginBottom: 15 }}
                  onChange={(value) =>
                    this.handleStorageTypeChange(
                      { target: { value } },
                      index,
                      "type"
                    )
                  }
                >
                  {storageTypes.map((storageType, typeIndex) => (
                    <Option key={typeIndex} value={storageType}>
                      {storageType}
                    </Option>
                  ))}
                </Select>
                <Tooltip title="Delete">
                  <Button onClick={() => this.DeleteStorage(null, index)}>
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
                {this.renderStorageConfig(storage, index)}
              </Form.Item>
            ))}

            <Form.Item>
              <Button
                type="primary"
                style={{ width: 250 }}
                optoinal={true}
                onClick={this.handleAddStorage}
              >
                {/* {t("Add_Storage")} Optional */}
                {t("Add_Storage")} <HddTwoTone twoToneColor="#008000" />{" "}
                (Optional)
              </Button>
            </Form.Item>

            {buildingList.map((building, index) => (
              <Form.Item
                label={<strong>Load {index + 1}</strong>}
                key={index}
                style={{ display: "flex", gap: "8px", width: "100%" }}
              >
                <Select
                  value={building.type}
                  style={{ width: 100, marginBottom: 10 }}
                  onChange={(value) =>
                    this.handleBuildingTypeChange(value, index, "type")
                  }
                >
                  {buildingtypes.map((buildingType, typeIndex) => (
                    <Option key={typeIndex} value={buildingType}>
                      {buildingType}
                    </Option>
                  ))}
                </Select>
                <Tooltip title="Delete">
                  <Button onClick={() => this.DeleteExtraB(null, index)}>
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
                {this.renderBuildingConfig(building, index)}
              </Form.Item>
            ))}

            <Form.Item>
              <Button
                type="primary"
                style={{ width: 250 }}
                onClick={this.handleAddBuilding}
              >
                {/* {t("Add_Load")} */}
                {t("Add_Load")} <HomeTwoTone twoToneColor="#eb2f96" />
              </Button>
            </Form.Item>
            <br></br>
            <button
              style={{
                marginBottom: 50,
                width: 150,
              }}
              type="submit"
              // onClick={() => this.setState({ verrouE: !this.state.verrouE })} // Toggles the verrouE state when clicked
            >
              {verrouE
                ? // ? ("Update")
                  t("Update")
                : //  ("Configure")}{" "}
                  t("Configure")}{" "}
              {/* Displays Update when verrouE is true, otherwise Configure */}
            </button>
            {verrouE && (
              <button
                style={{
                  marginBottom: 10,
                }}
                type="default"
                onClick={this.handleCancel} // Trigger cancel logic
              >
                {t("Cancel")}
                {/* {"Cancel"} */}
              </button>
            )}

            <div>
              {/* <div style={{ flex: 1, width: '600',  padding: '20px' }}> */}
              <Table
                columns={columns}
                dataSource={mappedConfigurations}
                loading={loading}
                pagination={paginationConfig}
              />
              <Modal
                title="Confirm Delete"
                visible={this.state.visibleModal}
                width={320}
                onOk={this.handleConfirmDelete}
                onCancel={this.handleCancelDelete}
              >
                <p>Are you sure you want to delete this configuration?</p>
              </Modal>
            </div>
          </div>
        </div>
        {/*      </div> */}
      </form>
    );
  }
}

// export default ConfigurationForm;
export default withTranslation(ConfigurationForm);
